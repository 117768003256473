import React, { Component } from 'react';
import ImageGallery from 'react-image-gallery';
import roster from '../data/totmfishermen.json'
import Layout from '../components/layout';
import Helmet from 'react-helmet';
import "react-image-gallery/styles/css/image-gallery.css";
import background from '../images/newspaper.jpg'

const Fishermen = () => {
  let images = [];
  roster.fishermen.forEach(fisherman =>{
    let image = {
      original: fisherman.image,
      description: `${fisherman.first_name ? fisherman.first_name : ""} ${fisherman.last_name ? fisherman.last_name : ""}`
    }
    images.push(image);
  });
    
  
  return (
    <Layout>
        <Helmet>
          <title>TOTM Fishermen</title>
      </Helmet>
        <section id="page-title" className="page-title-parallax page-title-dark" style={{backgroundImage: `url('${background}')`, padding: '120px 0'}} data-bottom-top="background-position:0px 300px;" data-top-bottom="background-position:0px -300px;">
            <div className="container clearfix page-title">
                <h1>TOTM Fishermen</h1>
                <span>Here are some of our successful fishermen who have taken the course</span>
            </div>
        </section>
        <section id="content">
            <div className="content-wrap">
                <div className="container clearfix">
                    <div className="row col-mb-50">
                        <div className="col-12">
                            <ImageGallery items={images} />
                        </div>
                    </div>
                </div>
            </div>    
        </section>          
    </Layout>
  )
}

export default Fishermen